<template>
    <div
        class="adslib__header"
        :class="{designs: selectedTab == 'designs-tab'}">
        <div class="adslib__left">
            <div
                class="adslib__button"
                @click.stop="showDropdown">
                <div class="adslib__circle">
                    <div class="adslib__plus">
                        +
                    </div>
                </div>
                CREATE NEW
                <create-new-dropdown
                    v-if="dropdownActive"
                    v-outside-click="hideDropdown" />
            </div>
        </div>
        <div class="adslib__right">
            <div
                ref="tabsWrapper"
                class="adslib__tabs-wrapper">
                <div
                    class="adslib__tab"
                    :class="{'active-tab': selectedTab == 'ads-tab'}"
                    @click="selectTab('ads-tab')">
                    Ads
                </div>
                <div
                    class="adslib__tab"
                    :class="{'active-tab': selectedTab == 'playbooks-tab'}"
                    @click="selectTab('playbooks-tab')">
                    Playbooks
                </div>
                <div
                    class="adslib__tab"
                    :class="{'active-tab': selectedTab == 'assets-tab'}"
                    @click="selectTab('assets-tab')">
                    Assets
                </div>
                <div
                    class="adslib__tab"
                    :class="{'active-tab': selectedTab == 'designs-tab'}"
                    @click="selectTab('designs-tab')">
                    Designs <span class="beta-label">BETA</span>
                </div>
            </div>
            <div class="adslib__active">
                <action-button
                    icon="list"
                    size="25"
                    @click="goToCampaignManagement">
                    Active Deployments
                </action-button>
            </div>
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import CreateNewDropdown from '@/components/globals/CreateNewDropdown';
import EventBus from '@/event-bus';
import { mapState } from 'vuex';

export default {
    components: {
        ActionButton,
        CreateNewDropdown
    },
    props: {
        selectedTab: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            dropdownActive: false,
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            isLayeredDesignerActive: (state) => state.layeredDesigner.isLayeredDesignerActive,
        }),
    },
    watch: {
        isLayeredDesignerActive() {
            this.dropdownActive = false;
        }
    },
    created() {
        EventBus.$on('new-layered-design-saved', this.handleUpdateDesigns);
    },
    methods: {
        goToCampaignManagement() {
            this.$router.push({
                name: 'campaign-management',
                params: {
                    dealer_id: this.currentDealerId
                },
            });
        },
        showDropdown() {
            this.dropdownActive = true;
        },
        hideDropdown() {
            this.dropdownActive = false;
        },
        selectTab(tab) {
            if (this.$route.name != tab) {
                this.$router.push({
                    name: tab,
                });
            }
        },
        handleUpdateDesigns() {
            this.selectTab('designs-tab');
        }
    }
};
</script>

<style lang="scss" scoped>
.adslib{
    &__header{
        height: 85px;
        display: flex;
    }
    &__left{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        border-bottom: 3px solid #F7F8FC;
    }
    &__right{
        width: 80%;
        display: flex;
        justify-content: space-between;
        padding: 30px;
        align-items: center;
        background: #EDF2F5;
    }
    &__button{
        position: relative;
        color: white;
        padding: 14px 40px;
        padding-left: 60px;
        border-radius: 50px;
        background: #7FD41B;
        font-weight: 600;
        cursor: pointer;
        &:after{
            content: '';
            position: absolute;
            width: 11px;
            height:11px;
            background: white;
            right: 18px;
            top: 16px;
            transform: rotate(45deg);
            z-index: 1;
        }
        &:before{
            content: '';
            position: absolute;
            width: 20px;
            height:15px;
            background: #7FD41B;
            right: 13px;
            top: 7px;
            z-index: 2;
        }
    }
    &__circle{
        position: absolute;
        left:0;
        width:50px;
        height: 50px;
        background: white;
        border-radius: 50%;
        display: flex;
        color: #7FD41B;
        align-items: center;
        justify-content: center;
        padding-bottom: 2px;
        font-size: 50px;
        top:-1px;
        box-shadow: 0 0 5px 2px rgba(0,0,0,0.25);
    }
    &__plus{
        background-color: #7FD41B;
        color: transparent;
        text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
    }

    &__tab{
        padding: 5px 45px;
        background: white;
        display: inline-block;
        border: 1px solid #DADADA;
        cursor: pointer;
        position: relative;
        &:first-of-type{
            border-right: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:last-of-type{
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        &.active-tab{
            background: #00A4EB;
            color: white;
            border: 1px solid #00A4EB;
            font-weight: 700;
        }
    }
}
.beta-label {
    font-size: 7px;
    margin-left: 5px;
    display: inline-block;
    position: absolute;
    top: 12px;
    right: 23px;
}
</style>

