<template>
    <div class="adslib">
        <ads-library-header :selected-tab="activeTab" />
        <router-view :key="$route.path" />
    </div>
</template>

<script>
import AdsLibraryHeader from '@/components/ads-library/AdsLibraryHeader';

export default {
    components: {
        AdsLibraryHeader,
    },
    data() {
        return {
            activeTab: '',
        };
    },
    mounted() {
        this.activeTab = this.$route.name;
    },
};
</script>

<style lang="scss" scoped>
.adslib{
    position: relative;
    width: calc(100% + 48px);
    top:-24px;
    left:-24px;
    right:-24px;
    bottom:-24px;
    @media only screen and (max-width: 959px) {
        width: calc(100% + 32px);
        top:-16px;
        left:-16px;
        right:-16px;
        bottom:-16px;
    }
}
</style>
<style lang="scss">
.adslib{
    &__body{
        .filters{
            label{
                font-size: 14px;
            }
            .v-messages{
                display: none;
            }
            .v-input__slot{
                margin-bottom: 0;
            }
        }
    }
    .adslib__select .v-input__slot{
        margin-bottom: 5px;
        &:before, &:after{
            display: none;
        }
    }

    .adslib__select .v-input__slot .v-label{
        display: none;
    }
    .adslib__select .v-input__icon{
        transform: translateX(-10px);
    }
    .adslib__select .v-input__slot input,
    .adslib__select .v-select__selection--comma,
    .adslib__select .v-input__slot .v-icon,
    .accent--text{
        color: #03A2EA !important;
        white-space: nowrap;
    }
    .adslib__select .v-select__selection--comma{
        text-decoration: underline;
    }
    .card .action-button-text{
        color: white;
        font-weight: 600;
    }
}
</style>

